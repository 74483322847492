import React from 'react';

function RemoveIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.75 4.5H16.25M14.75 4.5V15C14.75 15.75 14 16.5 13.25 16.5H5.75C5 16.5 4.25 15.75 4.25 15V4.5M6.5 4.5V3C6.5 2.25 7.25 1.5 8 1.5H11C11.75 1.5 12.5 2.25 12.5 3V4.5M8 8.25V12.75M11 8.25V12.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default RemoveIcon;
