import React from 'react';

function QrCodeIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.75 12H13.5C13.1022 12 12.7206 12.158 12.4393 12.4393C12.158 12.7206 12 13.1022 12 13.5V15.75M15.75 15.75V15.7575M9 5.25V7.5C9 7.89782 8.84196 8.27936 8.56066 8.56066C8.27936 8.84196 7.89782 9 7.5 9H5.25M2.25 9H2.2575M9 2.25H9.0075M9 12V12.0075M12 9H12.75M15.75 9V9.0075M9 15.75V15M3 2.25H5.25C5.66421 2.25 6 2.58579 6 3V5.25C6 5.66421 5.66421 6 5.25 6H3C2.58579 6 2.25 5.66421 2.25 5.25V3C2.25 2.58579 2.58579 2.25 3 2.25ZM12.75 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3V5.25C15.75 5.66421 15.4142 6 15 6H12.75C12.3358 6 12 5.66421 12 5.25V3C12 2.58579 12.3358 2.25 12.75 2.25ZM3 12H5.25C5.66421 12 6 12.3358 6 12.75V15C6 15.4142 5.66421 15.75 5.25 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V12.75C2.25 12.3358 2.58579 12 3 12Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default QrCodeIcon;
