import { useModal } from '@app/components/modal-views/context';
import { LinkDetailsResponse } from '@app/store/formUrl/types';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import AnalyticsIcon from '@app/components/icons/analytics/analytics';
import CopyIcon from '@app/components/icons/copy-icon';
import EditIcon from '@app/components/icons/edit';
import GoogleformIcon from '@app/components/icons/googleform';
import QrCodeIcon from '@app/components/icons/qrcode';
import RemoveIcon from '@app/components/icons/remove';
import ShareIcon from '@app/components/icons/share';
import ThreeDotIcon from '@app/components/icons/threedot';
import { useCopyToClipBoard } from '@app/lib/hooks/use-copy-to-clipboard';
import { Button } from '@app/shadcn/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@app/shadcn/components/ui/dropdown-menu';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useToast } from '@app/shadcn/hooks/use-toast';

interface CustomizeUrlCardProps extends LinkDetailsResponse {
    className?: string;
    landingPage?: boolean;
}

export default function CustomizeUrlCard({ id, destinationUrl, mappedDomain, mappedPath, className, landingPage = false }: CustomizeUrlCardProps) {
    const customizeUrl = `${window.location.protocol}//${mappedDomain}/to/${mappedPath}`;
    const router = useRouter();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { copyToClipboard } = useCopyToClipBoard();

    const { toast } = useToast();

    const { openModal } = useModal();

    const handleQRClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        openModal('QR_MODAL', { customizeUrl: customizeUrl, formId: id });
    };

    const handleCustomizeLinkClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        router.push(`/dashboard/${id}/edit`);
    };

    const handleEditFormLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        router.push(`/dashboard/${id}/edit`);
    };

    const handleDeleteFormLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        openModal('DELETE_MODAL', { form_id: id });
    };

    const handleShareFormLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        openModal('SHARE_MODAL', { customizeUrl: customizeUrl });
    };

    const handleGenerateQrCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        router.push(`/dashboard/${id}/qr`);
    };

    const handleClickEllipsis = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOnClickAnalytics = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        router.push(`/dashboard/${id}`);
    };

    const handleCopyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        copyToClipboard(customizeUrl).then(() => {
            toast({ title: 'Form URL Copied' });
        });
    };

    function getLastPathName() {
        return router.asPath.split('/').pop()?.trim();
    }

    function getCardsActionBtn() {
        const lastPathName = getLastPathName();
        if (lastPathName === 'qr') {
            return (
                <Button size={'sm'} onClick={handleCustomizeLinkClick}>
                    Edit Link
                </Button>
            );
        } else if (lastPathName === 'dashboard') {
            return (
                <>
                    <Button preIcon={<AnalyticsIcon />} size={'md'} variant={'secondary-grey'} onClick={handleOnClickAnalytics}>
                        <h1 className="hidden xs:flex">Analytics</h1>
                    </Button>
                    <Button preIcon={<QrCodeIcon />} size={'md'} variant={'secondary-grey'} onClick={handleQRClick} />
                    <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                        <DropdownMenuTrigger>
                            <Button preIcon={<ThreeDotIcon />} size={'md'} variant={'secondary-grey'} onClick={handleClickEllipsis} />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="rounded-xl p-1 shadow-lighterShadow">
                            <DropdownMenuItem className="rounded-[8px] rounded-b-none border border-gray-200 px-4 py-3" onClick={handleEditFormLink}>
                                <EditIcon className="h-4 w-4 text-neutral-800" />
                                <span className="text-neutral-800">Edit</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem className="border border-b-0 border-t-0 border-gray-200 px-4 py-3" onClick={handleDeleteFormLink}>
                                <RemoveIcon className="h-4 w-4 text-neutral-800" />
                                <span className="text-neutral-800">Delete</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem className="rounded-[8px] rounded-t-none border border-gray-200 px-4 py-3" onClick={handleShareFormLink}>
                                <ShareIcon className="h-4 w-4 fill-current text-neutral-800" />
                                <span className="text-neutral-800">Share</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            );
        } else {
            return (
                <>
                    <Button preIcon={<QrCodeIcon />} size={'sm'} variant={'secondary-grey'} onClick={handleGenerateQrCode}>
                        Generate QR
                    </Button>
                    <Button size={'sm'} onClick={handleCustomizeLinkClick}>
                        Edit Link
                    </Button>
                </>
            );
        }
    }

    const handleOnClickCard = () => {
        const lastPathName = getLastPathName();
        if (lastPathName === 'dashboard') {
            router.push(`/dashboard/${id}`);
        }
    };

    return (
        <>
            {landingPage ? (
                <div className="flex w-full flex-row items-center justify-between rounded-2xl border-gray-200 bg-white p-4 text-gray-500 hover:border-primary hover:bg-white hover:shadow-input-focus">
                    <div className="flex w-3/5 flex-row items-center gap-2 xl:w-full">
                        <Button size={'sm'} variant={'secondary-grey'} preIcon={<GoogleformIcon />} />
                        <div className="flex w-full flex-col gap-1">
                            <h1 className="body-14-medium truncate">{customizeUrl}</h1>
                            <h1 className="body-14-regular w-1/2 truncate text-gray-500 lg:w-7/12">{destinationUrl}</h1>
                        </div>
                    </div>
                    <Button preIcon={<CopyIcon />} onClick={handleCopyLink} size={'sm'} variant={'secondary-grey'}>
                        Copy
                    </Button>
                </div>
            ) : (
                <div
                    className={`relative w-full grid-cols-12 flex-col items-start gap-5 rounded-2xl border border-gray-200 bg-white p-4 text-gray-500 hover:border-primary hover:bg-white hover:shadow-input-focus lg:grid lg:flex-row lg:items-center ${getLastPathName() === 'dashboard' ? 'cursor-custom' : ''} ${className}`}
                    onClick={handleOnClickCard}
                >
                    <div className="col-span-4 flex flex-1 flex-row items-start gap-4 lg:basis-1/3 lg:items-center lg:gap-2">
                        <Button size={'sm'} variant={'secondary-grey'} preIcon={<GoogleformIcon />} />
                        <div className="flex w-full flex-col gap-4">
                            <div className="flex w-full flex-col gap-1">
                                <div className="flex w-8/12 flex-row items-center">
                                    <h1 className="body-16-medium mr-2 truncate text-gray-900 hover:text-primary lg:text-sm">{mappedPath}</h1>
                                    <div>
                                        <CopyIcon className="h-4 w-4 hover:text-gray-900" onClick={handleCopyLink} />
                                    </div>
                                </div>
                                <div className="flex w-9/12 sm:w-10/12 lg:hidden">
                                    <h1 className="w-full truncate">{destinationUrl}</h1>
                                </div>
                            </div>
                            <Separator className="visible w-10/12 lg:hidden" />
                        </div>
                    </div>

                    <div className="col-span-4 hidden lg:flex">
                        <h1 className="w-full truncate">{destinationUrl}</h1>
                    </div>
                    <div className="col-span-4 flex justify-start gap-3 pl-10 pt-4 sm:pl-16 lg:justify-end lg:pl-0 lg:pt-0">{getCardsActionBtn()}</div>
                </div>
            )}
        </>
    );
}
